import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import config from "@config";
import { ENVIRONMENT } from "@models/enums";

const AmplitudeInit = () => {
  useEffect(() => {
    if (typeof window === "undefined" || config.ENVIRONMENT !== ENVIRONMENT.LIVE) return;
    amplitude.init("0ba253744413a919f6e087a60fa0e263", undefined, {
      autocapture: true,
    });
  }, []);

  return null;
};

export default AmplitudeInit;